.send-broadcast {
  height: auto !important;
  .auth-form-container {
    background-color: white;
    padding: 20px;
  }
  .auth-form-section-wrapper {
    background-color: white;
    height: auto;
  }
  .ant-typography {
    color: black;
  }

  .ant-typography-secondary {
    color: black !important;
  }
  .ant-typography.ant-typography-success {
    color: #52c41a;
  }
  .ant-typography.ant-typography-danger {
    color: #ff4d4f;
  }
  .ant-form-item-no-colon {
    color: black;
  }
}
